import { MutationTypes } from "@/constants/MutationTypes";

export default {
  /* Industries */
  [MutationTypes.SET_INDUSTRIES]: (state, payload) => {
    state.industries = payload;
  },

  [MutationTypes.ADD_UPDATE_INDUSTRY]: (state, payload) => {
    state.industries = state.industries.filter(item => item.id !== payload.id);
    state.industries.push(payload);
  },

  [MutationTypes.DELETE_INDUSTRY]: (state, payload) => {
    state.industries = state.industries.filter(item => item.id !== payload);
  },

  /* Tags */
  [MutationTypes.SET_TAGS]: (state, payload) => {
    state.tags = payload;
  },

  /* Disciplines */
  [MutationTypes.SET_DISCIPLINES]: (state, payload) => {
    state.disciplines = payload;
  },

  [MutationTypes.ADD_UPDATE_DISCIPLINE]: (state, payload) => {
    state.disciplines = state.disciplines.filter(
      item => item.id !== payload.id
    );
    state.disciplines.push(payload);
  },

  [MutationTypes.DELETE_DISCIPLINE]: (state, payload) => {
    state.disciplines = state.disciplines.filter(item => item.id !== payload);
  },

  /* Specialities */
  [MutationTypes.SET_SPECIALITIES]: (state, payload) => {
    state.specialities = payload;
  },

  [MutationTypes.ADD_UPDATE_SPECIALITY]: (state, payload) => {
    state.specialities = state.specialities.filter(
      item => item.id !== payload.id
    );
    state.specialities.push(payload);
  },

  [MutationTypes.SET_TITLE_RANKS]: (state, payload) => {
    state.titleRanks = payload;
  },

  [MutationTypes.SET_TITLE_FUNCTIONS]: (state, payload) => {
    state.titleFunctions = payload;
  },

  [MutationTypes.SET_SERVICES]: (state, payload) => {
    state.services = payload;
  },

  [MutationTypes.SET_MEDIA_TYPES]: (state, payload) => {
    state.mediaTypes = payload;
  },

  [MutationTypes.SET_SOCIALS]: (state, payload) => {
    state.socials = payload;
  },

  /* Counts */
  [MutationTypes.SET_COUNTS]: (state, payload) => {
    state.entityCounts = payload;
  },

  [MutationTypes.UPDATE_COUNTS]: (state, {stateVariableName, count }) => {
    state.entityCounts = {
      ...state.entityCounts,
      [stateVariableName]: count
    };
  },

  /* Global loader */
  [MutationTypes.SHOW_LOADER]: (state, payload) => {
    state.globalLoaderVisible = true;
  },

  [MutationTypes.HIDE_LOADER]: (state, payload) => {
    state.globalLoaderVisible = false;
  },

  [MutationTypes.SHOW_NOTIFICATION](state, { type, message }) {
    state.notification.type = type;
    state.notification.message = message;
  },

  [MutationTypes.CLEAR_NOTIFICATION](state) {
    state.notification.type = "";
    state.notification.message = "";
  },

  [MutationTypes.SET_PROFILE_PAGE]: (state, payload) => {
    state.isProfilePage = payload;
  },

  [MutationTypes.BLUR_ACTIVE_ELEMENT]: () => {
    document.activeElement.blur();
  },

  [MutationTypes.SET_STATES]: (state, payload) => {
    state.states = payload;
  },

  [MutationTypes.SET_TV_REGIONS]: (state, payload) => {
    state.tvRegions = payload;
  },

  [MutationTypes.SET_NAICS]: (state, payload) => {
    state.naics = payload;
  },

  [MutationTypes.SET_HOLDING_COMPANIES]: (state, payload) => {
    state.holdingCompanies = payload;
  },

  [MutationTypes.SET_AGENCY_NETWORKS]: (state, payload) => {
    state.agencyNetworks = payload;
  },

  [MutationTypes.SET_EMPLOYEE_RANGES]: (state, payload) => {
    state.employeeRanges = payload;
  },

  [MutationTypes.SET_LOCATIONS]: (state, payload) => {
    state.locations = payload;
  },

  [MutationTypes.SET_LOCATION_PEOPLE]: (state, payload) => {
    state.location_people = payload;
  },
};
