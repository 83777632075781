import { DashboardUrl } from "@/constants/DashboardUrl";
import { getGenericFullResponse } from "@/api/_generic/_generic";

export function getDashboard() {
  const url = DashboardUrl.base;
  return getGenericFullResponse(url);
}

export function datePickerCounts({ start_date, end_date }) {
  const url = `${DashboardUrl.base}/${DashboardUrl.entityCounts}?start_date=${start_date}&end_date=${end_date}`;
  return getGenericFullResponse(url);
}
