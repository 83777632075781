import * as TagsApi from "@/api/tags/tags";
import * as IndustriesApi from "@/api/industries/industries";
import * as TitleFunctionsApi from "@/api/title-functions/title-functions";
import * as TitleRanksApi from "@/api/title-ranks/title-ranks";
import * as DisciplinesApi from "@/api/disciplines/disciplines";
import * as SpecialityApi from "@/api/speciality/speciality";
import * as ServicesApi from "@/api/services/services";
import * as MediaTypesApi from "@/api/media-types/media-types";
import * as SocialsApi from "@/api/socials/socials";
import * as StatesApi from "@/api/states/states";
import * as TvRegionsApi from "@/api/tv_regions/tv_regions";
import * as NaicsApi from "@/api/naics/naics";
import * as HoldingCompaniesApi from "@/api/holding-companies/holding-companies";
import * as AgencyNetworksApi from "@/api/agency-networks/agency-networks"
import * as EmployeeRangesApi from '@/api/employee-ranges/employee-ranges';
import * as LocationsApi from "@/api/locations/locations";

import { MutationTypes } from "@/constants/MutationTypes";

export default {
  getTags({ commit }) {
    return TagsApi.getAllTags().then(({ data }) => {
      commit(MutationTypes.SET_TAGS, data);
    });
  },

  getIndustries({ commit }) {
    return IndustriesApi.getAllIndustries().then(({ data }) => {
      commit(MutationTypes.SET_INDUSTRIES, data);
    });
  },

  createIndustry({ commit }, payload) {
    return IndustriesApi.createIndustry(payload).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_INDUSTRY, data);
    });
  },

  createDiscipline({ commit }, payload) {
    return DisciplinesApi.createDiscipline(payload).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_DISCIPLINE, data);
    });
  },

  getTitleRanks({ commit }) {
    return TitleRanksApi.getAll()
      .then(({ data }) => {
        commit(MutationTypes.SET_TITLE_RANKS, data);
      })
      .catch(e => {
        throw e;
      });
  },

  getTitleFunctions({ commit }, orgType) {
    return TitleFunctionsApi.getAll(orgType)
      .then(({ data }) => {
        commit(MutationTypes.SET_TITLE_FUNCTIONS, data);
      })
      .catch(e => {
        throw e;
      });
  },

  getDisciplines({ commit }) {
    return DisciplinesApi.getAllDisciplines().then(({ data }) => {
      commit(MutationTypes.SET_DISCIPLINES, data);
    });
  },

  createTag({ commit, getters }, payload) {
    const allData = getters.TAGS || [];

    return TagsApi.createTag(payload).then(({ data }) => {
      allData.push(data);
      commit(MutationTypes.SET_TAGS, allData);
    });
  },

  getSpecialities({ commit }, orgType) {
    return SpecialityApi.getAllSpecialities(orgType).then(({ data }) => {
      commit(MutationTypes.SET_SPECIALITIES, data);
    });
  },

  createSpeciality({ commit, getters }, payload) {
    return SpecialityApi.createNewSpeciality(payload).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_SPECIALITY, data);
    });
  },

  updateSpeciality({ commit, getters }, { id, payload }) {
    return SpecialityApi.updateSpeciality(id, payload).then(({ data }) => {
      commit(MutationTypes.ADD_UPDATE_SPECIALITY, data);
    });
  },

  getMediaTypes({ commit }) {
    return MediaTypesApi.getAll()
      .then(({ data }) => {
        commit(MutationTypes.SET_MEDIA_TYPES, data);
      })
      .catch(e => {
        throw e;
      });
  },

  getServices({ commit }) {
    return ServicesApi.getAll()
      .then(({ data }) => {
        commit(MutationTypes.SET_SERVICES, data);
      })
      .catch(e => {
        throw e;
      });
  },

  getSocials({ commit }) {
    return SocialsApi.getAll()
      .then(({ data }) => {
        commit(MutationTypes.SET_SOCIALS, data);
      })
      .catch(e => {
        throw e;
      });
  },

  getStates({ commit }) {
    return StatesApi.getAll().then(({ data }) => {
      commit(MutationTypes.SET_STATES, data);
    });
  },

  getTvRegions({ commit }) {
    return TvRegionsApi.getAll().then(({ data }) => {
      commit(MutationTypes.SET_TV_REGIONS, data);
    });
  },

  getNaics({ commit }) {
    return NaicsApi.getAll().then(({ data }) => {
      commit(MutationTypes.SET_NAICS, data);
    });
  },

  getHoldingCompanies({ commit }) {
    return HoldingCompaniesApi.getAll().then(({ data }) => {
      commit(MutationTypes.SET_HOLDING_COMPANIES, data);
    });
  },

  getAgencyNetworks({ commit }) {
    return AgencyNetworksApi.getAll().then(({ data }) => {
      commit(MutationTypes.SET_AGENCY_NETWORKS, data);
    });
  },

  getEmployeeRanges({ commit }, entityType) {
    return EmployeeRangesApi.getEmployeeRanges(entityType)
      .then(({ data }) => {
        commit(MutationTypes.SET_EMPLOYEE_RANGES, data);
      })
      .catch(e => {
        throw e;
      });
  },

  getLocations({ commit }) {
    return LocationsApi.getAllLocations().then(({ data }) => {
      commit(MutationTypes.SET_LOCATIONS, data);
    });
  },

  getLocationPeople({ commit }, id) {
    return LocationsApi.getLocationPeople(id).then(({ data }) => {
      commit(MutationTypes.SET_LOCATION_PEOPLE, data);
    });
  },
};
