export const MutationTypes = {
  /* Business */
  SET_ORGANIZATIONS: "SET_ORGANIZATIONS",

  RESET_STATES: "RESET_STATES",
  SET_EDIT: "SET_EDIT",
  SET_SAVE: "SET_SAVE",
  SET_CANCEL: "SET_CANCEL",
  SET_CHANGE: "SET_CHANGE",
  SET_INVALID: "SET_INVALID",

  SET_COMPANIES: "SET_COMPANIES",
  ADD_UPDATE_COMPANY: "ADD_UPDATE_COMPANY",

  SET_AGENCIES: "SET_AGENCIES",
  ADD_UPDATE_AGENCY: "ADD_UPDATE_AGENCY",

  SET_BUSINESS_LOCATIONS: "SET_BUSINESS_LOCATIONS",
  CREATE_BUSINESS_LOCATIONS: "CREATE_BUSINESS_LOCATIONS",
  UPDATE_BUSINESS_LOCATIONS: "UPDATE_BUSINESS_LOCATIONS",

  SET_BUSINESS_PROFILE_CONTACTS: "SET_BUSINESS_PROFILE_CONTACTS",
  UPDATE_BUSINESS_PROFILE_CONTACTS: "UPDATE_BUSINESS_PROFILE_CONTACTS",
  REMOVE_BUSINESS_PROFILE_CONTACTS: "REMOVE_BUSINESS_PROFILE_CONTACTS",

  SET_BUSINESS_BRANDS: "SET_BUSINESS_BRANDS",
  UPDATE_BUSINESS_BRANDS: "UPDATE_BUSINESS_BRANDS",
  SET_BUSINESS_NICKNAMES: "SET_BUSINESS_NICKNAMES",

  SET_BUSINESS_REVENUES: "SET_BUSINESS_REVENUES",

  SET_BUSINESS_SOCIALS: "SET_BUSINESS_SOCIALS",
  SET_BUSINESS_TAGS: "SET_BUSINESS_TAGS",
  SET_BUSINESS_NOTES: "SET_BUSINESS_NOTES",
  SET_BUSINESS_LOGS: "SET_BUSINESS_LOGS",

  SET_ENTITY_LOGO: "SET_ENTITY_LOGO",

  SET_ENTITY_PARENT_HIERARCHIES: "SET_ENTITY_PARENT_HIERARCHIES",
  SET_ENTITY_CHILD_HIERARCHIES: "SET_ENTITY_CHILD_HIERARCHIES",
  SET_ENTITY_SIBLING_HIERARCHIES: "SET_ENTITY_SIBLING_HIERARCHIES",
  UPDATE_ENTITY_PARENT_HIERARCHIES: "UPDATE_ENTITY_PARENT_HIERARCHIES",
  UPDATE_ENTITY_CHILD_HIERARCHIES: "UPDATE_ENTITY_CHILD_HIERARCHIES",
  REMOVE_ENTITY_PARENT_HIERARCHIES: "REMOVE_ENTITY_PARENT_HIERARCHIES",
  REMOVE_ENTITY_CHILD_HIERARCHIES: "REMOVE_ENTITY_CHILD_HIERARCHIES",

  SET_BUSINESS_INDUSTRIES: "SET_BUSINESS_INDUSTRIES",

  SET_BUSINESS_DISCIPLINES: "SET_BUSINESS_DISCIPLINES",
  ADD_BUSINESS_DISCIPLINES: "ADD_BUSINESS_DISCIPLINES",
  REMOVE_BUSINESS_DISCIPLINES: "REMOVE_BUSINESS_DISCIPLINES",

  SET_BUSINESS_SPECIALITIES: "SET_BUSINESS_SPECIALITIES",
  SET_BUSINESS_RELATIONSHIPS: "SET_BUSINESS_RELATIONSHIPS",
  ADD_BUSINESS_RELATIONSHIPS: "ADD_BUSINESS_RELATIONSHIPS",
  UPDATE_BUSINESS_RELATIONSHIPS: "UPDATE_BUSINESS_RELATIONSHIPS",
  SET_BUSINESS_AGENCY_CONTACTS: "SET_BUSINESS_AGENCY_CONTACTS",
  REMOVE_BUSINESS_AGENCY_CONTACTS: "REMOVE_BUSINESS_AGENCY_CONTACTS",

  SET_AGENCY_RELATIONSHIP: "SET_AGENCY_RELATIONSHIP",
  ADD_AGENCY_RELATIONSHIP: "ADD_AGENCY_RELATIONSHIP",
  UPDATE_AGENCY_RELATIONSHIP: "UPDATE_AGENCY_RELATIONSHIP",

  /* Brands */
  SET_BRANDS: "SET_BRANDS",
  ADD_UPDATE_BRAND: "ADD_UPDATE_BRAND",

  SET_BRAND_TAGS: "SET_BRAND_TAGS",
  SET_BRAND_SOCIALS: "SET_BRAND_SOCIALS",
  SET_BRAND_NICKNAMES: "SET_BRAND_NICKNAMES",
  SET_BRAND_INDUSTRIES: "SET_BRAND_INDUSTRIES",

  SET_BRAND_DEMOGRAPHICS: "SET_BRAND_DEMOGRAPHICS",

  SET_BRAND_CONTACTS: "SET_BRAND_CONTACTS",
  REMOVE_BRAND_CONTACTS: "REMOVE_BRAND_CONTACTS",

  SET_BRAND_NOTES: "SET_BRAND_NOTES",
  SET_BRAND_LOGS: "SET_BRAND_LOGS",
  SET_BRAND_SPECIALITIES: "SET_BRAND_SPECIALITIES",
  SET_BRAND_SPONSORSHIPS: "SET_BRAND_SPONSORSHIPS",
  ADD_BRAND_SPONSORSHIPS: "ADD_BRAND_SPONSORSHIPS",
  UPDATE_BRAND_SPONSORSHIPS: "UPDATE_BRAND_SPONSORSHIPS",
  SET_BRAND_BUSINESS_RELATIONSHIPS: "SET_BRAND_BUSINESS_RELATIONSHIPS",
  ADD_BRAND_BUSINESS_RELATIONSHIPS: "ADD_BRAND_BUSINESS_RELATIONSHIPS",
  UPDATE_BRAND_BUSINESS_RELATIONSHIPS: "UPDATE_BRAND_BUSINESS_RELATIONSHIPS",
  SET_BRAND_BUSINESSES: "SET_BRAND_BUSINESSES",
  SET_BRAND_BUSINESSES_CONTACTS: "SET_BRAND_BUSINESSES_CONTACTS",
  SET_BRAND_AGENCY_CONTACTS: "SET_BRAND_AGENCY_CONTACTS",
  UPDATE_BRAND_AGENCY_CONTACTS: "UPDATE_BRAND_AGENCY_CONTACTS",
  REMOVE_BRAND_AGENCY_CONTACTS: "REMOVE_BRAND_AGENCY_CONTACTS",

  /* Person */
  SET_PROFILE_CONTACTS: "SET_PROFILE_CONTACTS",
  ADD_UPDATE_PROFILE_CONTACT: "ADD_UPDATE_PROFILE_CONTACT",

  SET_PERSON_SOCIALS: "SET_PERSON_SOCIALS",
  SET_PERSON_TAGS: "SET_PERSON_TAGS",
  SET_PERSON_NOTES: "SET_PERSON_NOTES",
  SET_PERSON_SPECIALITIES: "SET_PERSON_SPECIALITIES",
  SET_PERSON_LOGS: "SET_PERSON_LOGS",

  SET_PERSON_TITLE_FUNCTIONS: "SET_PERSON_TITLE_FUNCTIONS",
  SET_PERSON_WORK_HISTORIES: "SET_PERSON_WORK_HISTORIES",
  SET_PERSON_BRANDS: "SET_PERSON_BRANDS",
  SET_PERSON_LOCATION: "SET_PERSON_LOCATION",
  SET_PERSON_ORGANIZATION: "SET_PERSON_ORGANIZATION",

  /* User */
  SET_USER: "SET_USER",
  SET_AUTH: "SET_AUTH",
  SET_USERS: "SET_USERS",
  SET_USER_ROLES: "SET_USER_ROLES",

  /* Report */
  SET_REPORTS: "SET_REPORTS",

  /* Helpers */
  SET_COUNTS: "SET_COUNTS",
  UPDATE_COUNTS: "UPDATE_COUNTS",
  SET_TASKS: "SET_TASKS",
  SET_USER_TASKS: "SET_USER_TASKS",
  UPDATE_USER_TASK: "UPDATE_USER_TASK",

  SET_INDUSTRIES: "SET_INDUSTRIES",
  ADD_UPDATE_INDUSTRY: "ADD_UPDATE_INDUSTRY",
  DELETE_INDUSTRY: "DELETE_INDUSTRY",

  SET_TAGS: "SET_TAGS",

  SET_DISCIPLINES: "SET_DISCIPLINES",
  ADD_UPDATE_DISCIPLINE: "ADD_UPDATE_DISCIPLINE",
  DELETE_DISCIPLINE: "DELETE_DISCIPLINE",

  SET_SPECIALITIES: "SET_SPECIALITIES",
  ADD_UPDATE_SPECIALITY: "ADD_UPDATE_SPECIALITY",

  SET_TITLE_RANKS: "SET_TITLE_RANKS",
  SET_TITLE_FUNCTIONS: "SET_TITLE_FUNCTIONS",

  SET_SERVICES: "SET_SERVICES",

  SET_MEDIA_TYPES: "SET_MEDIA_TYPES",

  SET_SOCIALS: "SET_SOCIALS",

  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",

  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",

  SET_PROFILE_PAGE: "SET_PROFILE_PAGE",

  BLUR_ACTIVE_ELEMENT: "BLUR_ACTIVE_ELEMENT",

  SET_PARSE_KANTAR_DATA: "SET_PARSE_KANTAR_DATA",

  SET_FILTER_OPTIONS: "SET_FILTER_OPTIONS",

  SET_LOCATION_FILTER_OPTIONS: "SET_LOCATION_FILTER_OPTIONS",

  SET_POSTAL_CODES_FILTER_OPTIONS: "SET_POSTAL_CODES_FILTER_OPTIONS",

  SET_INDUSTRIES_FILTER_OPTIONS: "SET_INDUSTRIES_FILTER_OPTIONS",

  SET_TITLE_FUNCTIONS_FILTER_OPTIONS: "SET_TITLE_FUNCTIONS_FILTER_OPTIONS",

  SET_TITLE_RANKS_FILTER_OPTIONS: "SET_TITLE_RANKS_FILTER_OPTIONS",

  SET_EMPLOYEE_RANGES: "SET_EMPLOYEE_RANGES",

  WINBOX_SYNC: "WINBOX_SYNC",

  SET_DATA_IMPORT_TEMPLATES: "SET_DATA_IMPORT_TEMPLATES",

  SET_STATES: "SET_STATES",
  SET_TV_REGIONS: "SET_TV_REGIONS",
  SET_NAICS: "SET_NAICS",
  SET_HOLDING_COMPANIES: "SET_HOLDING_COMPANIES",
  SET_AGENCY_NETWORKS: "SET_AGENCY_NETWORKS",
  SET_LOCATIONS: "SET_LOCATIONS",
  SET_LOCATION_PEOPLE: "SET_LOCATION_PEOPLE",
};
