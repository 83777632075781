var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoaded
    ? _c("div", { staticClass: "agency-profile-wrap" }, [
        _c("div", { staticClass: "columns is-desktop" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" General ")]
                        },
                        proxy: true
                      },
                      {
                        key: "headerIcon",
                        fn: function() {
                          return [
                            _vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button save-button",
                                    attrs: { disabled: !_vm.isChanged },
                                    on: {
                                      click: function($event) {
                                        return _vm.save(true)
                                      }
                                    }
                                  },
                                  [_vm._v("\n            Save\n          ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button cancel-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.cancel(true)
                                      }
                                    }
                                  },
                                  [_vm._v("\n            Cancel\n          ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isEditMode
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button edit-button",
                                    on: {
                                      click: function($event) {
                                        return _vm.edit(true)
                                      }
                                    }
                                  },
                                  [_vm._v("\n            Edit\n          ")]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3509522888
                  )
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.entity.name),
                        attrs: {
                          label: "Agency Name",
                          disabled: !_vm.isEditMode,
                          field: "name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.addToDataUpdate("name", _vm.entity.name)
                          }
                        },
                        model: {
                          value: _vm.$v.entity.name.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.name,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.name.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns" }, [
                        _c(
                          "div",
                          { staticClass: "column is-12" },
                          [
                            _c("winbox-select", {
                              attrs: {
                                "form-label": "Agency Holding Company",
                                options: _vm.possibleHoldingCompanies,
                                disabled: !_vm.isEditMode,
                                clearable: _vm.isEditMode
                              },
                              on: { input: _vm.onHoldingCompanyChange },
                              model: {
                                value: _vm.entity.holdingCompany,
                                callback: function($$v) {
                                  _vm.$set(_vm.entity, "holdingCompany", $$v)
                                },
                                expression: "entity.holdingCompany"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns" }, [
                        _c(
                          "div",
                          { staticClass: "column is-12" },
                          [
                            _c("winbox-select", {
                              attrs: {
                                "form-label": "Holding Company Network",
                                options: _vm.possibleAgencyNetworks,
                                disabled:
                                  !_vm.isEditMode || !_vm.entity.holdingCompany,
                                clearable: _vm.isEditMode
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addToDataUpdate(
                                    "agencyNetwork",
                                    _vm.entity && _vm.entity.agencyNetwork
                                  )
                                }
                              },
                              model: {
                                value: _vm.entity.agencyNetwork,
                                callback: function($$v) {
                                  _vm.$set(_vm.entity, "agencyNetwork", $$v)
                                },
                                expression: "entity.agencyNetwork"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns general-columns" }, [
                        _c(
                          "div",
                          { staticClass: "column is-3" },
                          [
                            _c("winbox-select", {
                              class: _vm.status(_vm.$v.entity.org_type),
                              attrs: {
                                options: _vm.entityTypes,
                                "form-label": "Entity Type",
                                disabled: !_vm.isEditMode,
                                clearable: _vm.isEditMode
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addToDataUpdate(
                                    "org_type",
                                    _vm.entity &&
                                      _vm.entity.org_type &&
                                      _vm.entity.org_type.value
                                  )
                                }
                              },
                              model: {
                                value: _vm.entity.org_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.entity, "org_type", $$v)
                                },
                                expression: "entity.org_type"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-2" },
                          [
                            _c("winbox-select", {
                              attrs: {
                                "form-label": "Employee Number",
                                options: _vm.possibleEmployeeRanges,
                                disabled: !_vm.isEditMode,
                                clearable: _vm.isEditMode
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addToDataUpdate(
                                    "employee_range_id",
                                    _vm.entity &&
                                      _vm.entity.employeeRange &&
                                      _vm.entity.employeeRange.value
                                  )
                                }
                              },
                              model: {
                                value: _vm.entity.employeeRange,
                                callback: function($$v) {
                                  _vm.$set(_vm.entity, "employeeRange", $$v)
                                },
                                expression: "entity.employeeRange"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-2" },
                          [
                            _c("winbox-input", {
                              class: _vm.status(_vm.$v.entity.year_founded),
                              attrs: {
                                label: "Founded",
                                maxlength: "4",
                                disabled: !_vm.isEditMode
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addToDataUpdate(
                                    "year_founded",
                                    _vm.entity.year_founded
                                  )
                                }
                              },
                              model: {
                                value: _vm.$v.entity.year_founded.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.entity.year_founded,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.entity.year_founded.$model"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-3" },
                          [
                            _c("winbox-select", {
                              attrs: {
                                options: _vm.fiscalCloses,
                                "form-label": "Fiscal Close",
                                disabled: !_vm.isEditMode,
                                clearable: _vm.isEditMode
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addToDataUpdate(
                                    "fiscal_close",
                                    _vm.entity.fiscal_close
                                  )
                                }
                              },
                              model: {
                                value: _vm.entity.fiscal_close,
                                callback: function($$v) {
                                  _vm.$set(_vm.entity, "fiscal_close", $$v)
                                },
                                expression: "entity.fiscal_close"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column is-2" },
                          [
                            _c("winbox-input", {
                              attrs: {
                                label: "Stock Symbol",
                                disabled: !_vm.isEditMode
                              },
                              on: {
                                input: function($event) {
                                  return _vm.addToDataUpdate(
                                    "stock_symbol",
                                    _vm.entity.stock_symbol
                                  )
                                }
                              },
                              model: {
                                value: _vm.entity.stock_symbol,
                                callback: function($$v) {
                                  _vm.$set(_vm.entity, "stock_symbol", $$v)
                                },
                                expression: "entity.stock_symbol"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.entity.short_description),
                        attrs: {
                          label: "Short Description",
                          disabled: !_vm.isEditMode
                        },
                        on: {
                          input: function($event) {
                            return _vm.addToDataUpdate(
                              "short_description",
                              _vm.entity.short_description
                            )
                          }
                        },
                        model: {
                          value: _vm.$v.entity.short_description.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.short_description,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.short_description.$model"
                        }
                      }),
                      _vm._v(" "),
                      _vm.isEditMode
                        ? _c("winbox-input", {
                            class: _vm.status(_vm.$v.entity.website),
                            attrs: {
                              label: "Web Address",
                              "icon-left": "globe-americas",
                              field: "website"
                            },
                            on: {
                              input: function($event) {
                                return _vm.addToDataUpdate(
                                  "website",
                                  _vm.entity.website
                                )
                              }
                            },
                            model: {
                              value: _vm.$v.entity.website.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.entity.website,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.entity.website.$model"
                            }
                          })
                        : _c("fieldset", { staticClass: "border" }, [
                            _c("legend", { staticClass: "float-none w-auto" }, [
                              _vm._v("Web Address")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "website-container" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "website-link",
                                  attrs: {
                                    href: `http://${_vm.entity.website}`,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        decodeURIComponent(_vm.entity.website)
                                      ) +
                                      "\n                "
                                  )
                                ]
                              )
                            ])
                          ]),
                      _vm._v(" "),
                      _c("winbox-textarea", {
                        class: _vm.status(_vm.$v.entity.long_description),
                        attrs: {
                          "form-label": "Long Description",
                          disabled: !_vm.isEditMode
                        },
                        on: {
                          input: function($event) {
                            return _vm.addToDataUpdate(
                              "long_description",
                              _vm.entity.long_description
                            )
                          }
                        },
                        model: {
                          value: _vm.$v.entity.long_description.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.long_description,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.long_description.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c("winbox-input", {
                        class: _vm.status(_vm.$v.entity.nickname),
                        attrs: { label: "Nickname", disabled: !_vm.isEditMode },
                        on: {
                          input: function($event) {
                            return _vm.addToDataUpdate(
                              "nickname",
                              _vm.entity.nickname
                            )
                          }
                        },
                        model: {
                          value: _vm.$v.entity.nickname.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.entity.nickname,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.entity.nickname.$model"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  attrs: { expandable: true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Disciplines ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    157631867
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-disciplines", {
                        attrs: { disabled: !_vm.isEditMode },
                        on: {
                          changed: function($event) {
                            return _vm.onTagsChanged($event, "name")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns is-desktop" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Social Media ")]
                        },
                        proxy: true
                      },
                      {
                        key: "headerIcon",
                        fn: function() {
                          return [
                            _vm.isEditMode
                              ? _c("winbox-icon", {
                                  attrs: {
                                    icon: "plus-circle",
                                    color: _vm.Colors.system.primaryLight
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.$refs.winboxSocialMediaRef.addNewSocialEntity.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2820771390
                  )
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-social-media", {
                        ref: "winboxSocialMediaRef",
                        attrs: {
                          items: _vm.socialEntities,
                          disabled: !_vm.isEditMode
                        },
                        on: { changed: _vm.onSocialChanged }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column" },
            [
              _c(
                "winbox-card",
                {
                  attrs: { expandable: true },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [_vm._v(" Speciality Lists ")]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3565595510
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("winbox-speciality-tags-list", {
                        attrs: {
                          "possible-tags": _vm.possibleTags,
                          "entity-tags": _vm.selectedTags,
                          "org-type": "agency",
                          disabled: !_vm.isEditMode
                        },
                        on: {
                          changed: function($event) {
                            return _vm.onTagsChanged($event, "name")
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }