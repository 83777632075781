<template>
  <div class="front-dashboard-wrap">
    <winbox-notification type="info">{{ notifMessage }}</winbox-notification>

    <winbox-large-indicator v-if="!isLoaded" />
    <div v-if="isLoaded" class="front-dashboard-content">
      <div class="columns title-area date-picker-container">
        <div class="column is-narrow" />
        <winbox-input
          v-model="selectedDateRange"
          placeholder="Input a date range to show number of new entities"
          :date-picker="true"
          :date-range="true"
          orientation="bottom"
          @input="fetchEntityCounts"
        />
        <button v-show="selectedDateRange" @click="clearDatePicker" class="clear-date-btn">Clear</button>
      </div>
      <div class="columns top-overview-cards">
        <div
          class="column is-2.5"
          v-for="(item, key) in overviewCards"
          :key="key"
        >
          <winbox-card class="item-overview">
            <div class="item-title">
              <winbox-title :title="item.displayTitle" type="medium" />
            </div>
            <div class="item-count">{{ item.count | numeral("0,0") }}</div>
            <div class="item-descr">{{ item.descr }}</div>
          </winbox-card>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <winbox-user-tasks @task-completed="updateCompletionPercentage"/>
        </div>
      </div>
      <div class="columns is-desktop top-graph-cards">
        <div class="column is-half-desktop" v-if="isAdmin()">
          <winbox-total-confirmed :value="confirmedData" />
        </div>
        <div class="column is-half-desktop align-right">
          <winbox-task-completion :value="completionPercentage" :user="currentUser" />
        </div>
      </div>
      <div v-show="false" class="columns total-updates-wrap">
        <div class="column">
          <winbox-front-dashboard-widget :value="totalUpdatesdData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import WinboxCard from "@/components/Atoms/WinboxCard";
import WinboxTitle from "@/components/Atoms/WinboxTitle";
import WinboxInput from "@/components/Atoms/WinboxInput";
import WinboxLargeIndicator from "@/components/Atoms/LoadingIndicators/WinboxLargeIndicator";

import WinboxUserTasks from "@/components/Organisms/WinboxUserTasks";

import WinboxNotification from "@/components/Molecules/WinboxNotification";
import WinboxTaskCompletion from "@/components/Molecules/WinboxTaskCompletion";
import WinboxTotalConfirmed from "@/components/Molecules/WinboxTotalConfirmed";
import WinboxFrontDashboardWidget from "@/components/Molecules/WinboxFrontDashboardWidget";
import { RolesMixin } from "@/components/Pages/Generic/RolesMixin";

import { Colors } from "@/Colors";
import * as Utils from "@/utils";
import * as DashboardApi from "@/api/dashboard/dashboard";
import moment from "moment";

const defaultLastDays = { label: "Last 30 days", value: 30 };

export default {
  name: "FrontDashboard",
  mixins: [
    RolesMixin,
  ],
  components: {
    WinboxCard,
    WinboxTitle,
    WinboxInput,
    WinboxUserTasks,
    WinboxLargeIndicator,
    WinboxNotification,
    WinboxTaskCompletion,
    WinboxTotalConfirmed,
    WinboxFrontDashboardWidget,
  },
  data() {
    return {
      isLoaded: false,
      currentUser: {
        fullName: "",
      },
      selectedDateRange: '',
      entityCounts: [],
      currentLastDays: defaultLastDays,
      filterDayOptions: [
        defaultLastDays,
        { label: "Last 60 days", value: 60 },
        { label: "Last 90 days", value: 90 },
        { label: "Last 120 days", value: 120 },
      ],
      overviewCards: [
        {
          title: "Contacts",
          displayTitle: "Contacts",
          count: 0
        },
        {
          title: "Companies",
          displayTitle: "Companies",
          count: 0
        },
        {
          title: "Brands",
          displayTitle: "Brands",
          count: 0
        },
        {
          title: "Agencies",
          displayTitle: "Agencies",
          count: 0
        },
        {
          title: "AgencyRelationships",
          displayTitle: "Agency Relationships",
          count: 0
        },
      ],
      confirmedData: {
        chartData: {
          labels: [],
          datasets: [],
        },
      },
      completedData: {
        progress: 0,
      },
      totalUpdatesdData: {
        title: "Total Updates",
        subtitle: "Datapoints Updated",
        chartComponent: "horizontal-bar",
        count: 1926,
        currentLastDays: defaultLastDays,
        infoStatuses: [
          {
            icon: "arrow-up",
            status:
              "You have a <span class='positive'>3% growth</span> in comparison with previous month",
          },
          {
            icon: "circle-notch",
            status: "<span class='positive'>77%</span> of 2500 goal",
          },
        ],
        chartData: {
          labels: ["Title", "Email", "Direct phone", "Brand", "Address"],
          datasets: Utils.dummyBarChartDataset(),
        },
      },
      notifMessage: "",
      Colors: Colors,
    };
  },
  watch: {
    currentLastDays: {
      handler() {
        this.totalUpdatesdData.currentLastDays = this.currentLastDays;
      },
    },
  },
  beforeMount() {
    this.loadData();
  },
  computed: {
    ...mapState([
      'userTasks',
    ]),
    completionPercentage() {
      return this.completedData;
    },
  },
  methods: {
    ...mapActions([
      'getUserTasks',
    ]),
    loadData() {
      this.isLoaded = false;

      this.getUserTasks();

      // Emulate API call
      DashboardApi.getDashboard().then((response) => {
        const responseData = response.data;

        this.overviewCards.forEach((card) => {
          if (response.data[card.title]) {
            card.count += response.data[card.title];
          }
        });

        this.generateTotalConfirmedData(response.data.latest);
        this.currentUser.fullName = response.data.currentUser;
        this.notifMessage = `Hi ${this.currentUser.fullName}, Welcome to Winbox!`;
        this.updateCompletionPercentage();
        this.isLoaded = true;
      });
    },
    async fetchEntityCounts() {
      if (!this.selectedDateRange || this.selectedDateRange.split(" - ").length !== 2) {
        this.entityCounts = [];
        return;
      }
      const [startDate, endDate] = this.selectedDateRange.split(" - ");
      if (!startDate || !endDate) {
        this.entityCounts = [];
        return;
      }
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

      const formattedEndDate = adjustedEndDate.toISOString().split('T')[0];
      try {
        const response = await DashboardApi.datePickerCounts({
          start_date: startDate,
          end_date: formattedEndDate,
        });
        if (response && response.data) {
          this.entityCounts = response.data;
          this.updateOverviewCards();
        }
      } catch (error) {
        console.error("Error fetching entity counts:", error);
        this.$notify({
          type: 'error',
          message: 'Failed to fetch entity counts. Please try again later.',
        });
      }
    },
    updateOverviewCards() {
      this.overviewCards.forEach((card) => {
        const entityData = this.entityCounts.find((entity) => entity.entity === card.displayTitle);
        card.count = entityData ? entityData.number_added : 0;
      });
    },
    clearDatePicker() {
      this.selectedDateRange = null;
      this.loadData();
    },
    updateCompletionPercentage() {
      let userTasks = this.userTasks.slice();
      userTasks.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      let earliestNonCompletedTaskDate = null;
      let completedUserTasks = [];

      userTasks.forEach((userTask) => {
        if (userTask.status === 'Completed') {
          completedUserTasks.push(userTask);
        } else if (!earliestNonCompletedTaskDate) {
          earliestNonCompletedTaskDate = new Date(new Date(userTask.created_at).toDateString());
        }
      });

      if (!earliestNonCompletedTaskDate) {
        earliestNonCompletedTaskDate = new Date();
      }

      let validUserTasks = userTasks.filter((userTask) => {
        return new Date(userTask.created_at) >= earliestNonCompletedTaskDate
      });
      let validCompletedUserTasks = completedUserTasks.filter((completedUserTask) => {
        return new Date(completedUserTask.created_at) >= earliestNonCompletedTaskDate
      });
      if (validUserTasks.length === 0) {
        this.completedData.progress = 100;
      } else {
        this.completedData.progress = Math.ceil(validCompletedUserTasks.length / (validUserTasks.length || 1) * 100);
      };
    },
    onFilterDaysChanged(e) {
      this.currentLastDays = e;
    },
    generateTotalConfirmedData(data) {
      let monthData = this.getMonthByRange(
        data.latestDateStart,
        data.latestDateEnd
      );
      this.confirmedData.chartData.labels = monthData.map((item) => item.label);
      let latestCompany = this.generateLatestData(
        data.latestCompany,
        monthData,
        "Companies"
      );
      let latestAgency = this.generateLatestData(
        data.latestAgency,
        monthData,
        "Agencies"
      );
      let latestBrands = this.generateLatestData(
        data.latestBrand,
        monthData,
        "Brands"
      );
      let latestContact = this.generateLatestData(
        data.latestContact,
        monthData,
        "Contacts"
      );
      let latestRelationships = this.generateLatestData(
        data.latestRelationships,
        monthData,
        "AgencyRelationships"
      );

      this.confirmedData.chartData.datasets.push(latestCompany);
      this.confirmedData.chartData.datasets.push(latestAgency);
      this.confirmedData.chartData.datasets.push(latestBrands);
      this.confirmedData.chartData.datasets.push(latestContact);
      this.confirmedData.chartData.datasets.push(latestRelationships);

      this.confirmedData.from = moment(data.latestDateStart);
      this.confirmedData.to = moment(data.latestDateEnd);
    },
    getMonthByRange(start_date, end_date) {
      let dateStart = moment(start_date);
      let dateEnd = moment(end_date);
      let timeValues = [];

      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        timeValues.push({
          label: dateStart.format("DD MMM"),
          value: dateStart.format("M"),
        });
        dateStart.add(1, "month");
      }

      return timeValues;
    },
    generateLatestData(data, month, label) {
      let result = {};
      let chartData = month.map((item) => {
        if (data[item.value]) {
          return data[item.value];
        }
        return 0;
      });
      result = Utils.chartDatasetTemplate(label, chartData);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/../assets/stylesheets/variables/variables";
@import "~@/../assets/stylesheets/variables/mixins/mixins";

::v-deep {
  .winbox-title {
    .title,
    .subtitle {
      font-weight: bold;
    }
  }
}
.filter-by-last-days {
  width: 220px;
  margin-left: auto;
}
.top-overview-cards {
  .item-count {
    font-size: $winbox-font-size-huge;
    line-height: 1;
    font-weight: bold;
    text-align: center;
  }
  .item-descr {
    font-size: 21px;
    color: $winbox-secondary-title;
    opacity: 0.7;
    text-align: center;
  }
  .item-title {
    margin: 0 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.item-draggable {
  @include card-draggable-icon;
}
.align-right {
  margin-left: auto;
  margin-right: 0;
}
.date-picker-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  padding: 0 0.75rem 0 0;
}
.clear-date-btn {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 10px;
}
.clear-date-btn:hover {
  background-color: #e0e0e0;
}
</style>
